import React from 'react';

export default function Terms() {
  return <div className="terms">
    <p>
      <a href="https://opensource.facebook.com/legal/terms">Terms of Use</a>
      &emsp;
      <a href="https://opensource.facebook.com/legal/privacy">Privacy</a>
    </p>
  </div>;
}
