import React from 'react';

export default function Header() {
  return (
    <header>
      <div className="header__content">
        <nav>
          <a href="https://www.opensfm.org/docs/building.html">Quickstart</a>
          <a href="https://www.opensfm.org/docs/">Documentation</a>
          <a href="https://github.com/mapillary/OpenSfM">
            <i className="icon-github" />
            Source code
          </a>
        </nav>
        <div className="header__sponsor">
          Sponsored by{' '}
          <a href="https://www.mapillary.com" rel="nofollow noopener">
            <img
              src={require('../assets/mapillary-logo.svg')}
              alt="Mapillary"
            />
          </a>
        </div>
      </div>
    </header>
  );
}
