import React, { useEffect, useState, Fragment } from 'react';
import FontFaceObserver from 'fontfaceobserver';

import Header from './components/Header';
import Hero from './components/Hero';
import Visualize from './components/Visualize';
import Footer from './components/Footer';
import './styles/index.scss';
import Attribution from './components/Attribution';
import Terms from './components/Terms';

function App() {
  useEffect(() => {
    async function onLoad() {
      const font = new FontFaceObserver('Source Sans Pro');
      await font.load();
      document.querySelector('#root').style.opacity = 1;
    }
    onLoad();
  }, []);
  return (
    <Fragment>
      <Header />
      <Hero />
      <Attribution />
      <Terms />
      {/* <Visualize /> */}
      {/* <Footer /> */}
    </Fragment>
  );
}

export default App;
