import React, { useRef, useEffect } from 'react';

import ButtonLink from './ButtonLink';

const THREE = window.THREE;
const Potree = window.Potree;
const CameraControls = window.CameraControls;

CameraControls.install({ THREE: THREE });

export default function Hero() {
  const mount = useRef(null);
  useEffect(() => {
    let width = mount.current.clientWidth;
    let height = mount.current.clientHeight;
    let aspect = width / height;
    let frameId, pointMat;

    const clock = new THREE.Clock();
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(90, aspect, 0.1, 1000);
    // const orthoZoom = 20;
    // const camera = new THREE.OrthographicCamera(
    //   -width / orthoZoom,
    //   width / orthoZoom,
    //   height / orthoZoom,
    //   -height / orthoZoom,
    //   -100,
    //   1000
    // );
    // camera.fov = 70;
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      // logarithmicDepthBuffer: true,
      // antialias: true,
      precision: 'highp',
      premultipliedAlpha: true,
      preserveDrawingBuffer: false,
      powerPreference: 'high-performance',
    });
    renderer.setPixelRatio(window.devicePixelRatio);

    renderer.setSize(width, height);
    // camera.position.z = 10;
    // camera.position.y = 5;
    // camera.position.x = 5;

    const controls = new CameraControls(
      camera,
      document.querySelector('.hero')
    );
    controls.dampingFactor = 0.03;
    controls.minPolarAngle = Math.PI / 2;
    controls.maxPolarAngle = Math.PI / 2;
    controls.minDistance = 0.1;
    controls.maxDistance = 0.1;
    controls.setLookAt(0, 1, 0, 0.1, 1, 0.1, false);
    // controls.truck(0, -2.5, true);
    controls.rotate(Math.PI, 0, true);

    let prevTheta = 0;
    let panDirection = 'left';
    controls.addEventListener('controlend', e => {
      const nextTheta = e.target._spherical.theta;
      if (nextTheta > prevTheta) {
        panDirection = 'left';
      } else {
        panDirection = 'right';
      }
      prevTheta = nextTheta;
    });

    // Setup debug
    // scene.add(new THREE.AmbientLight(0x404040));
    // scene.add(new THREE.AxesHelper(1000));

    // Potree
    const points = new Potree.Group();
    points.setPointBudget(1000000);
    scene.add(points);
    Potree.Global.workerPath = 'source';
    Potree.loadPointCloud('data/levels3/cloud.js', 'filtered', data => {
      let material = data.pointcloud.material;
      let pointcloud = data.pointcloud;
      pointMat = material;
      material.size = 0.5;
      material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
      material.shape = Potree.PointShape.SQUARE;

      // pointcloud.position.copy(new THREE.Vector3(-1.5, -2.3, 1));
      pointcloud.position.copy(new THREE.Vector3(-50, -50, -12));
      points.add(pointcloud);
    });

    const renderScene = () => {};

    const animate = () => {
      // renderScene();
      const dt = clock.getDelta();
      controls.update(dt);
      renderer.render(scene, camera);
      if (pointMat) pointMat.time = 1;
      controls.rotate(panDirection === 'left' ? 0.0005 : -0.0005, 0, true);
      frameId = window.requestAnimationFrame(animate);
    };

    const handleResize = () => {
      width = mount.current.clientWidth;
      height = mount.current.clientHeight;
      aspect = width / height;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      // renderScene();
    };

    mount.current.appendChild(renderer.domElement);
    window.addEventListener('resize', handleResize);
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="hero">
      <h1 className="hero__title">OpenSfM</h1>
      <p className="hero__desc">
        An open-source Structure from Motion library that lets you build 3D
        models from images.
      </p>
      <ButtonLink href="https://www.opensfm.org/docs/" className="hero__cta">
        Get started with OpenSfM
      </ButtonLink>
      <div className="hero__visual" ref={mount} />
    </div>
  );
}
